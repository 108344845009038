import styled from 'styled-components';
import Colours from '../../constants/colours';
import {InputProps, StyleProps} from './Inputs.types';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Field = styled.input`
  width: 100%;
  box-sizing: border-box;
  border: 1px solid
    ${(p: InputProps) => (p.error ? Colours.red : Colours.grey[300])};
  font-size: 1rem;
  padding: 1rem;
  padding-left: ${(props: StyleProps) => (props.icon ? '4rem' : '1rem')};
  outline: none;

  background-size: 2rem;
  background-repeat: no-repeat;
  background-position: left 1rem center;
  background-image: ${(props: StyleProps) =>
    props.icon ? `url(/images/icons/${props.icon})` : 'none'};

  background-color: ${(props: StyleProps) =>
    props.disabled ? Colours.grey[100] : Colours.white};

  color: ${(props: StyleProps) =>
    props.disabled ? Colours.grey[500] : Colours.grey[800]};

  &:focus {
    border: 1px solid ${Colours.green};
  }
`;

export const HideShowIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: calc(50% - 12px);
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
