import {Box} from '../styles/page';
import styled from 'styled-components';
import {Text} from './Inputs';
import Typography from '../constants/typography';
import {NAVIGATION_COLLAPSE_POINT} from '../constants/layouts';

export const InputWrapper = styled(Box)`
  margin-top: 2em;
  margin-bottom: 40vh;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

interface InputProps {
  icon?: string;
}

export const Input = styled(Text)<InputProps>`
  margin-bottom: 1em;
`;

export const Heading = styled.h2`
  font-size: ${Typography.heading.desktop.fontSize};
  line-height: ${Typography.heading.desktop.lineHeight};

  @media (max-width: ${NAVIGATION_COLLAPSE_POINT}) {
    font-size: ${Typography.heading.mobile.fontSize};
    line-height: ${Typography.heading.mobile.lineHeight};
  }
`;
