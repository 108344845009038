import styled from 'styled-components';
import Colours from '../../constants/colours';
import Typography from '../../constants/typography';
import {Button} from '../Button';
// @ts-ignore
import loader from '../../images/button-loader.gif';

export const ErrorMessage = styled.div`
  background-color: ${Colours.red};
  color: ${Colours.white};
  font-size: ${Typography.body.desktop.fontSize};
  line-height: 1em;
  padding: 1em;
  text-align: center;
  margin-bottom: 1em;

  & > div {
    transform: translateY(2px);
  }
`;

export const SubmittingButton = styled(Button)`
  background-color: ${Colours.grey[100]};
  cursor: default;
  pointer-events: none;
  color: transparent;
  background-image: url(${loader});
  background-repeat: no-repeat;
  background-position: center;
`;
