import React, {useRef, useState, useEffect} from 'react';
import {InputProps} from './Inputs.types';
import {Wrapper, HideShowIcon, Field} from './Inputs.styles';
import Colours from '../../constants/colours';

export const Text = ({autoFocus, ...props}: InputProps) => {
  const ref = useRef<HTMLInputElement>();

  useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus, ref]);

  return <Field ref={ref} type="text" {...props} />;
};

export const Password = ({autoFocus, ...props}: InputProps) => {
  const ref = useRef<HTMLInputElement>();
  const [type, setType] = useState('password');

  React.useEffect(() => {
    if (autoFocus && ref.current) {
      ref.current.focus();
    }
  }, [autoFocus, ref]);

  const isHidden = () => type === 'password';

  const toggleType = () => {
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
  };

  return (
    <Wrapper>
      <HideShowIcon data-test-id="password-hide-show" onClick={toggleType}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <path
            fill={isHidden() ? Colours.grey[500] : Colours.green}
            d="M.2 10a11 11 0 0 1 19.6 0A11 11 0 0 1 .2 10zm9.8 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-2a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
          />
        </svg>
      </HideShowIcon>
      <Field ref={ref} type={type} {...props} />
    </Wrapper>
  );
};
