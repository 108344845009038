import React, {useEffect} from 'react';
import {graphql, navigate, PageProps} from 'gatsby';
import PageWidth from '../../components/PageWidth';
import {Layout} from '../../components/Layout';
import SEO from '../../components/SEO';
import {CouponInput} from '../../components/CouponInput';
import {PageWrapper, Skeleton, Box} from '../../styles/page';
import {getApiUrlFromData, getStudioPremiumPlanId} from '../../utils';
import {useAuth} from '../../services/auth';
import {CouponPageQuery} from './__generated__/CouponPageQuery';

const title = 'Enter your coupon code';

const CouponPage = (props: PageProps<CouponPageQuery>) => {
  const {
    isSubscribedToPlan,
    isLoggedIn,
    getUserInfo,
    user,
    attemptedFetch,
  } = useAuth();

  const baseUrl = getApiUrlFromData(props.data);
  const premiumId = getStudioPremiumPlanId(props.data);

  useEffect(() => {
    const refreshUserData = async () => {
      if (!attemptedFetch) {
        await getUserInfo(baseUrl);
      }
    };

    refreshUserData();
  }, [attemptedFetch, getUserInfo]);

  useEffect(() => {
    if (isSubscribedToPlan(premiumId)) {
      navigate('/subscription/success/?subscribed');
    }
  }, [isSubscribedToPlan, props.data, premiumId]);

  useEffect(() => {
    if (attemptedFetch && isLoggedIn === false) {
      navigate('/login?continue=/subscription/coupon');
    }
  }, [attemptedFetch, isLoggedIn]);

  return (
    <Layout>
      <SEO title={title} />
      <PageWrapper grey>
        <PageWidth>
          {user.id ? (
            <CouponInput
              user={{...user, id: user.id}}
              apiUrl={baseUrl}
              planId={premiumId}
            />
          ) : (
            <>
              <Skeleton />
              <Box style={{marginTop: '2rem'}}>
                <Skeleton />
              </Box>
            </>
          )}
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query CouponPageQuery {
    planData: allContentfulAmpifyWebPlans {
      edges {
        node {
          premiumId
        }
      }
    }
    site {
      siteMetadata {
        apiUrl
      }
    }
  }
`;

export default React.memo(CouponPage);
