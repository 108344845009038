import React, {useState, FunctionComponent, FormEvent} from 'react';
import {
  User,
  PaymentsApi,
  UserRedeemCouponRequest,
} from '@focusrite-novation/ampify-api';
import {createAuthedApi, parseApiError} from '../../api';
import {navigate} from 'gatsby';
import {Button} from '../Button';
import {Form, Input, InputWrapper, Heading} from '../InputHelpers';
import {ErrorMessage, SubmittingButton} from './CouponInput.styles';

interface CouponInputProps {
  apiUrl: string;
  user: User;
  planId: string;
}

export const CouponInput: FunctionComponent<CouponInputProps> = ({
  apiUrl,
  user,
  planId,
}) => {
  const title = 'Enter your coupon code';
  const [couponCode, setCouponCode] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const redirect = () => navigate('/login?continue=/subscription/coupon');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (couponCode.length === 0) {
      return;
    }

    try {
      setIsSubmitting(true);
      setErrorMessage('');

      const redeemCouponRequest: UserRedeemCouponRequest = {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        couponCode: couponCode.trim(),
        planId,
      };

      const api = createAuthedApi(PaymentsApi, apiUrl);
      await api.subscribe(redeemCouponRequest);

      navigate('/subscription/success/?method=coupon');
    } catch (error) {
      const apiError = parseApiError(error);
      setIsSubmitting(false);

      if (apiError?.statusCode >= 400 && apiError?.statusCode < 500) {
        setErrorMessage('This coupon code is not valid');
      } else {
        redirect();
      }
    }
  };

  return (
    <>
      {user ? (
        <>
          <Heading>
            {user ? `Hi ${user.firstName}! ${title}...` : title}
          </Heading>
          <InputWrapper>
            <Form onSubmit={handleSubmit} data-test-id="coupon-form">
              {errorMessage && (
                <ErrorMessage data-test-id="coupon-error-message">
                  <div>{errorMessage}</div>
                </ErrorMessage>
              )}
              <Input
                icon="coupon.svg"
                placeholder="Your coupon code"
                value={couponCode}
                onChange={(e) => setCouponCode(e.target.value)}
                data-test-id="coupon-input"
              />
              {isSubmitting ? (
                <SubmittingButton>Submitting coupon</SubmittingButton>
              ) : (
                <Button data-test-id="submit-coupon">Redeem coupon</Button>
              )}
            </Form>
          </InputWrapper>
        </>
      ) : null}
    </>
  );
};
